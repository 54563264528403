import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
//https://inmobiliaonline.wiggot.com/api/search?category_id=39&limit=&category=association&state=Colima&city=Colima&neighborhood=&longitude=-103.7240868&latitude=19.2452342&type=&operation=
import httpClient, { strapi } from "../../utils/httpClient";
import { Footer } from "../Footer";
import { useParams } from "react-router-dom";

import { Dropdown } from "react-css-dropdown";
import "react-css-dropdown/dist/index.css";
import numeral from "numeral";
import PropertyCard from "./PropertyCard";

function setDetailsHeight(selector, wrapper = document) {
  const setHeight = (detail, open = false) => {
    detail.open = open;
    const rect = detail.getBoundingClientRect();
    detail.dataset.width = rect.width;
    detail.style.setProperty(
      open ? `--expanded` : `--collapsed`,
      `${rect.height}px`
    );
  };
  const details = wrapper.querySelectorAll(selector);
  const RO = new ResizeObserver((entries) => {
    return entries.forEach((entry) => {
      const detail = entry.target;
      const width = parseInt(detail.dataset.width, 10);
      if (width !== entry.contentRect.width) {
        detail.removeAttribute("style");
        setHeight(detail);
        setHeight(detail, true);
        detail.open = false;
      }
    });
  });
  details.forEach((detail) => {
    RO.observe(detail);
  });
}

/* Run it */

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchStateQuery = {
  Colima: {
    url:
      "https://proxy.inmobiliaonline.com.mx/inmobiliaonline.wiggot.com:443/api/search?category_id=39&category=association&state=Colima&city=Colima&neighborhood=&longitude=-103.7240868&latitude=19.2452342&type=&operation=&limit=",
    img: "kyle-petzer-sD_JW9vvUUA-unsplash.jpg",
  },
};

const ValidatedInput = ({ placeholder, cRef, style }) => {
  return (
    <input
      placeholder={placeholder}
      type="number"
      min={0}
      ref={cRef}
      onChange={(e) => {
        if (e.target.value < 0) {
          e.target.value = 0;
        }
        e.target.value = parseInt(e.target.value);
      }}
      style={{
        fontSize: 18,
        width: 80,
        ...style,
      }}
    />
  );
};

const Properties = () => {
  let query = useQuery();
  let { id } = useParams();
  const [Response, setResponse] = useState({
    properties: [],
    page: 0,
    pages: 0,
    total: 0,
  });

  const [Options, setOptions] = useState([
    { name: "TODOS LOS TIPOS", value: "" },
    { name: "Casa", value: "HOUSE" },
    { name: "Terreno/Lote", value: "TERRAIN" },
    { name: "Bodega", value: "WAREHOUSE" },
    { name: "Departamento", value: "APARTMENT" },
    { name: "Oficina", value: "OFFICE" },
    { name: "Rancho/Hacienda", value: "RANCH" },
    { name: "Edificio", value: "BUILDING" },
    { name: "Salon/Terraza de eventos", value: "EVENT_HALL" },
    { name: "Otro", value: "OTHER" },
    { name: "Local", value: "SHOP" },
    { name: "Local Comercial", value: "SHOP_SHOPPING_CENTER" },
    { name: "Hotel", value: "HOTEL" },
  ]);

  const [CurrentSection, setCurrentSection] = useState();

  const [Limit, setLimit] = useState(20);

  const [Loading, setLoading] = useState(false);

  const priceFromRef = useRef();
  const priceToRef = useRef();
  const currencyRef = useRef();
  const roomsRef = useRef();
  const bathroomRef = useRef();
  const halfBathroomRef = useRef();
  const parkingRef = useRef();
  const landFromRef = useRef();
  const landToRef = useRef();

  const [SearchType, setSearchType] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [PriceRange, setPriceRange] = useState({});
  //&price=10000,1000000&currency=MXN

  const [Spaces, setSpaces] = useState({});
  //&bedrooms=1&bathrooms=1&halfBathrooms=1&parking=1

  const [Surface, setSurface] = useState({});
  //&ground=100,200

  // Cuartos-0++
  // Baños-0++
  // Medio Baños-0++
  // Estacionamientos-0++

  useEffect(() => {
    setLoading(true);
    strapi.get("/search-sections/" + id).then(({ data }) => {
      console.log("serach-sections", data);
      setCurrentSection(data);
      setLoading(false);
    });
    setDetailsHeight("details");
    return () => {};
  }, []);

  useEffect(() => {
    setLoading(true);
    let price = PriceRange.from
      ? `&price=${PriceRange.from},${PriceRange.to}&currency=${PriceRange.currency}`
      : "";
    let spaces =
      Spaces.bathroom != undefined
        ? `&bedrooms=${Spaces.rooms}&bathrooms=${Spaces.bathroom}&halfBathrooms=${Spaces.halfBathroom}&parking=${Spaces.parking}`
        : "";
    let surface =
      Surface.from != undefined ? `&ground=${Surface.from},${Surface.to}` : "";
    // if (CurrentSection)
    //   Axios.get(
    //     `https://proxy.inmobiliaonline.com.mx/inmobiliaonline.wiggot.com:443/api/search?category_id=39&category=association` +
    //       `&state=${CurrentSection.state}&city=${CurrentSection.city}&neighborhood=&longitude=-103.7240868&latitude=19.2452342` +
    //       `&type=${PropertyType}&operation=${SearchType}&limit=${Limit}${price}${spaces}${surface}`
    //   )
    //     .then(({ data }) => {
    //       console.log("Properties Response", data);
    //       setResponse(data);
    //     })
    //     .catch((err) => {})
    //     .then(() => {
    //       setLoading(false);
    //     });

    return () => {};
  }, [
    Limit,
    CurrentSection,
    SearchType,
    PropertyType,
    PriceRange,
    Spaces,
    Surface,
  ]);

  const Pages = [];
  for (let i = 0; i < Response.pages; i++) {
    Pages.push(i);
  }

  if (CurrentSection) {
    return (
      <iframe
        style={{
          display: "flex",
          height: "100vh",
          flex: 1,
          width: "100%",
        }}
        src={CurrentSection.url}
      ></iframe>
    );
  }

  if (!CurrentSection) {
    return null;
  }

  return (
    <div>
      <section
        className="banner-area section"
        style={{
          backgroundImage: `url(${CurrentSection.image.url})`,
          backgroundPosition: "top",
          marginBottom: 20,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="banner-content">
                <h1
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 80,
                  }}
                >
                  {CurrentSection.title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container"
        style={{
          marginBottom: 40,
          marginTop: 0,
        }}
      >
        <div
          className="col-xs-12 col-sm-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 0,
          }}
        >
          <button
            onClick={() => setSearchType(SearchType == "SALE" ? "" : "SALE")}
            className="btn btn-large shadow"
            style={{
              fontSize: 30,
              marginRight: 10,
              marginLeft: 10,
              backgroundColor: SearchType == "SALE" ? "#4873F6" : "transparent",
              border: "3px solid #4873F6",
              color: SearchType == "SALE" ? "#fff" : "#333",
            }}
          >
            VENTA
          </button>
        </div>
        <div
          className="col-xs-12 col-sm-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => setSearchType(SearchType == "LEASE" ? "" : "LEASE")}
            className="btn btn-large shadow"
            style={{
              fontSize: 30,
              marginRight: 10,
              marginLeft: 10,
              backgroundColor:
                SearchType == "LEASE" ? "#4873F6" : "transparent",
              border: "3px solid #4873F6",
              color: SearchType == "LEASE" ? "#fff" : "#333",
            }}
          >
            RENTA
          </button>
        </div>
        <div
          className="col-xs-12 col-sm-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() =>
              setSearchType(SearchType == "PRESALE" ? "" : "PRESALE")
            }
            className="btn btn-large shadow"
            style={{
              fontSize: 30,
              marginLeft: 10,
              marginRight: 10,
              backgroundColor:
                SearchType == "PRESALE" ? "#4873F6" : "transparent",
              border: "3px solid #4873F6",
              color: SearchType == "PRESALE" ? "#fff" : "#333",
            }}
          >
            PREVENTA
          </button>
        </div>
      </div>
      <div className="container">
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 30,
          }}
        >
          <div
            style={{
              float: "left",
            }}
          >
            <Dropdown
              containerClass="custom-drop"
              name={"Escoje el tipo"}
              options={Options}
              defaultSelection={PropertyType}
              handleSelect={(e) => {
                let value = e.target.value;
                console.log("handleSelect", value);
                setPropertyType(value);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="container filters-container"
        style={{
          position: "relative",

          zIndex: 999999,
        }}
      >
        <div
          className="row"
          style={{
            position: "absolute",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div
            className="col-xs-12 col-md-4"
            style={{
              //height : 300

              zIndex: 99999,
            }}
          >
            <details
              style={{
                zIndex: 9999,

                "--expanded": 380,
              }}
            >
              <summary className="shadow">
                <span data-css-icon="down">
                  Espacios<i></i>
                </span>
              </summary>
              <div
                className="shadow"
                style={{
                  backgroundColor: "#f4f4f4",
                  border: "0.5px solid #d1d1d1",
                  //height: 400,
                }}
              >
                <div className="row">
                  <div
                    className="col-xs-12"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 18,
                      }}
                    >
                      Cuartos
                    </span>{" "}
                    <ValidatedInput placeholder={"#"} cRef={roomsRef} />
                  </div>
                  <div
                    className="col-xs-12"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Baños</span>
                    <ValidatedInput placeholder={"#"} cRef={bathroomRef} />
                  </div>
                  <div
                    className="col-xs-12"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Medios Baños</span>
                    <ValidatedInput placeholder={"#"} cRef={halfBathroomRef} />
                  </div>
                  <div
                    className="col-xs-12"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Estacionamientos</span>
                    <ValidatedInput placeholder={"#"} cRef={parkingRef} />
                  </div>

                  <button
                    onClick={() => {
                      setSpaces({
                        rooms: roomsRef.current.value,
                        bathroom: bathroomRef.current.value,
                        halfBathroom: halfBathroomRef.current.value,
                        parking: parkingRef.current.value,
                      });
                    }}
                    className="btn btn-primary"
                  >
                    APLICAR
                  </button>
                  <button
                    onClick={() => {
                      roomsRef.current.value = "";
                      bathroomRef.current.value = "";
                      halfBathroomRef.current.value = "";
                      parkingRef.current.value = "";

                      setSpaces({});
                    }}
                    className="btn btn-danger"
                  >
                    BORRAR
                  </button>
                </div>
              </div>
            </details>
          </div>

          <div
            className="col-xs-12 col-md-4"
            style={{
              zIndex: 99999,
            }}
          >
            <details
              style={{
                zIndex: 9999,

                "--expanded": 230,
              }}
            >
              <summary className="shadow">
                <span data-css-icon="down">
                  Precio<i></i>
                </span>
              </summary>
              <div
                className="shadow"
                style={{
                  backgroundColor: "#f4f4f4",
                  border: "0.5px solid #d1d1d1",
                  //height: 400,
                }}
              >
                <div className="row">
                  <div className="col-xs-12">
                    <ValidatedInput
                      placeholder={"DESDE"}
                      cRef={priceFromRef}
                      style={{
                        fontSize: 20,
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="col-xs-12">
                    <ValidatedInput
                      placeholder={"HASTA"}
                      cRef={priceToRef}
                      style={{
                        fontSize: 20,
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="col-xs-12">
                    <select ref={currencyRef} placeholder="Currency">
                      <option>MXN</option>
                      <option>USD</option>
                    </select>
                  </div>
                  <button
                    onClick={() => {
                      setPriceRange({
                        from: priceFromRef.current.value,
                        to: priceToRef.current.value,
                        currency: currencyRef.current.value,
                      });
                    }}
                    className="btn btn-primary"
                  >
                    APLICAR
                  </button>
                  <button
                    onClick={() => {
                      priceFromRef.current.value = "";
                      priceToRef.current.value = "";

                      setPriceRange({});
                    }}
                    className="btn btn-danger"
                  >
                    BORRAR
                  </button>
                </div>
              </div>
            </details>
          </div>

          <div
            className="col-xs-12 col-md-4"
            style={{
              zIndex: 99999,
            }}
          >
            <details
              style={{
                zIndex: 9999,

                "--expanded": "290",
              }}
            >
              <summary className="shadow">
                <span data-css-icon="down">
                  Superficie<i></i>
                </span>
              </summary>
              <div
                className="shadow"
                style={{
                  backgroundColor: "#f4f4f4",
                  border: "0.5px solid #d1d1d1",
                  //height: 400,
                }}
              >
                <div className="row">
                  <div className="col-xs-12">
                    <ValidatedInput
                      placeholder={"DESDE"}
                      cRef={landFromRef}
                      style={{
                        fontSize: 20,
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="col-xs-12">
                    <ValidatedInput
                      placeholder={"HASTA"}
                      cRef={landToRef}
                      style={{
                        fontSize: 20,
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        setSurface({
                          from: landFromRef.current.value,
                          to: landToRef.current.value,
                        });
                      }}
                      className="btn btn-primary"
                    >
                      APLICAR
                    </button>
                    <button
                      onClick={() => {
                        landFromRef.current.value = "";
                        landToRef.current.value = "";

                        setSurface({});
                      }}
                      className="btn btn-danger"
                    >
                      BORRAR
                    </button>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>

        <style>{`

       
        button,
          details {
            width: 22rem;
          }


          .filters-container {
            height: 100px;
          }

          @media (max-width:360px){
            button,
            details {
              width: 20rem;
            }

            .filters-container {
              height: 250px;
            }

            ._1ul-b {
              width : 300px;
              padding: 1em 1.5em 1em 0.8em;
            }
          }

           @media (max-width:375px){
            button,
            details {
              width: 21.5rem;
            }

            .filters-container {
              height: 250px;
            }

            ._1ul-b {
              width : 300px;
              padding: 1em 1.5em 1em 0.8em;
            }
          }

          @media (max-width:768px){
            button,
            details {
              width: 14rem;
            }

            .filters-container {
              height: 100px;
            }

            ._1ul-b {
              width : 400px;
              padding: 1em 1.5em 1em 0.8em;
            }
          }

           @media (max-width:1024px){
            button,
            details {
              width: 18rem;
            }

            .filters-container {
              height: 100px;
            }

            ._1ul-b {
              width : 400px;
              padding: 1em 1.5em 1em 0.8em;
            }
          }

          * {
            user-select: none;
          }
          [data-css-icon] {
            --animdur: 0.3s;
            --loading-animdur: 0.8s;
            --animtf: ease-in;
            --bdw: 2px;
            --bdrs: 50%;
            --bgc: transparent;
            --c: currentcolor;
            --dots-bgc: silver;
            --dots-size: 0.5rem;
            --icon-size: 1rem;
            --size: 2.5rem;

            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
          }
          [data-css-icon] i {
            align-items: center;
            background-color: var(--bgc);
            border-radius: var(--bdrs);
            box-sizing: border-box;
            display: inline-flex;
            height: var(--size);
            justify-content: center;
            position: relative;
            transition: background-color var(--animdur) var(--animtf);
            width: var(--size);
          }
          [data-css-icon] i::after,
          [data-css-icon] i::before {
            transform-origin: 50% 50%;
            transition: all var(--animdur) var(--animtf);
          }

          [data-css-icon*="down"] i::after,
          [data-css-icon*="right"] i::after {
            background: transparent;
            border-color: var(--c);
            border-style: solid;
            box-sizing: border-box;
            content: "";
            display: inline-block;
            height: var(--icon-size);
            margin: 0;
            position: relative;
            width: var(--icon-size);
          }
          [data-css-icon*="down"] i::after {
            border-width: 0 var(--bdw) var(--bdw) 0;
            top: calc(0px - (var(--icon-size) / 4));
            transform: rotate(45deg);
          }
          [data-css-icon*="right"] i::after {
            border-width: var(--bdw) var(--bdw) 0 0;
            right: calc((var(--icon-size) / 4));
            top: 0;
            transform: rotate(45deg);
          }

          [data-css-icon*="equals"] i::after,
          [data-css-icon*="equals"] i::before,
          [data-css-icon*="cross"] i::after,
          [data-css-icon*="cross"] i::before,
          [data-css-icon*="menu"] i,
          [data-css-icon*="menu"] i::after,
          [data-css-icon*="menu"] i::before,
          [data-css-icon*="plus"] i::after,
          [data-css-icon*="plus"] i::before {
            /* Width need to be the diagonal of the down-arrow side-length (--size): sqrt(2) * --size. */
            --w: calc(var(--icon-size) * 1.4142135623730950488016887242097);
            background: var(--c);
            content: "";
            height: var(--bdw);
            position: absolute;
            width: var(--w);
          }
          [data-css-icon*="cross"] i::before,
          [data-css-icon*="plus"] i::before {
            transform: rotate(90deg);
          }
          [data-css-icon*="equals"] i {
            --m: 4px;
          }
          [data-css-icon*="equals"] i::after {
            transform: translateY(var(--m));
          }
          [data-css-icon*="equals"] i::before {
            transform: translateY(calc(0px - var(--m)));
          }
          [data-css-icon*="dots"],
          [data-css-icon*="menu"] {
            height: var(--size);
          }
          [data-css-icon*="menu"] i {
            --bdrs: 0;
            --m: 7px;
            position: relative;
            right: calc((var(--size) - var(--w)) / 2);
          }
          [data-css-icon*="menu"] i::after {
            top: var(--m);
          }
          [data-css-icon*="menu"] i::before {
            top: calc(0px - var(--m));
          }

          [data-css-icon*="dots"] i,
          [data-css-icon*="dots"] i::after,
          [data-css-icon*="dots"] i::before {
            animation: dots var(--loading-animdur) infinite alternate;
            background-color: var(--c);
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: var(--dots-size);
            width: var(--dots-size);
          }
          [data-css-icon*="dots"] i {
            animation-delay: var(--loading-animdur);
            position: relative;
            right: calc((var(--size) - var(--dots-size)) / 4);
          }
          [data-css-icon*="dots"] i::after {
            animation-delay: 0s;
            left: calc(0px - (var(--dots-size) * 3));
            position: absolute;
          }
          [data-css-icon*="dots"] i::before {
            animation-delay: calc(var(--loading-animdur) / 2);
            left: calc(0px - (var(--dots-size) * 1.5));
            position: absolute;
          }

          [data-css-icon*="spin"] i::after {
            animation: spin var(--loading-animdur) infinite linear;
            border-radius: 50%;
            border: var(--bdw) solid var(--dots-bgc);
            border-left: var(--bdw) solid var(--c);
            content: "";
            height: var(--icon-size);
            transform: translateZ(0);
            width: var(--icon-size);
          }

          /* STATE */
          [open] > summary > [data-css-icon*="cross"] i::after {
            transform: rotate(45deg);
          }
          [open] > summary > [data-css-icon*="cross"] i::before {
            transform: rotate(135deg);
          }
          [open] > summary > [data-css-icon*="down"] i::after {
            top: var(--bdw);
            transform: rotate(45deg) scale(-1);
          }
          [open] > summary > [data-css-icon*="right"] i::after {
            right: 0;
            top: calc(0px - (var(--icon-size) / 4));
            transform: rotate(135deg);
          }
          [open] > summary > [data-css-icon*="plus"] i::after {
            transform: rotate(180deg);
          }
          [open] > summary > [data-css-icon*="plus"] i::before {
            transform: rotate(-0deg);
          }

          [open] > summary > [data-css-icon*="equals"] i::after {
            transform: rotate(-45deg);
          }
          [open] > summary > [data-css-icon*="equals"] i::before {
            transform: rotate(45deg);
          }

          [open] > summary > [data-css-icon*="menu"] i {
            background-color: transparent;
          }
          [open] > summary > [data-css-icon*="menu"] i::after {
            transform: translateY(calc(0px - var(--m))) rotate(-45deg);
          }
          [open] > summary > [data-css-icon*="menu"] i::before {
            transform: translateY(var(--m)) rotate(45deg);
          }

          /* RTL: inset-block-end fails */
          [dir="rtl"] [data-css-icon*="right"] i::after {
            left: calc((var(--icon-size) / 4));
          }
          [dir="rtl"] [data-css-icon*="menu"] i {
            left: 0.5rem;
            right: auto;
          }

          /* MODIFIERS */
          [data-css-icon*="outline"] i {
            border: var(--bdw) solid var(--c);
          }
          [data-css-icon*="fill"] {
            --bgc: hsl(195, 10%, 30%);
            --c: hsl(195, 10%, 95%);
          }
          [data-css-icon*="square"] {
            --bdrs: 5px;
          }

         
          button {
            font-family: inherit;
            font-size: inherit;
          }
          details {
            height: var(--collapsed);
            overflow: hidden;
            transition: height 300ms cubic-bezier(0.4, 0.01, 0.165, 0.99);
          }
          details[open] {
            height: var(--expanded);
          }
          button,
          summary {
            background-color: var(--bgc);
            border: 0;
            border-radius: 5px;
            color: var(--c, inherit);
            list-style-type: none;
            margin: 0.5rem 0;
            outline: none;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            padding-inline-end: 0.5rem;
            padding-inline-start: 1rem;
            user-select: none;
          }
          summary::marker {
            display: none;
          }
          summary::-webkit-details-marker {
            display: none;
          }

          /* 4 States of summary */
          button,
          summary {
            --bgc: hsl(195, 10%, 90%);
          }
          [open] > summary {
            --bgc: hsl(195, 10%, 20%);
            --c: hsl(195, 10%, 92%);
          }
          button:focus,
          summary:focus {
            --bgc: hsl(195, 10%, 75%);
          }
          [open] > summary:focus {
            --bgc: hsl(195, 10%, 10%);
            --c: hsl(195, 10%, 99%);
          }
          [open] > summary:focus > [data-css-icon*="fill"],
          [open] > summary > [data-css-icon*="fill"] {
            --bgc: hsl(195, 10%, 80%);
            --c: hsl(195, 10%, 10%);
          }
          summary + * {
            color: #777;
            line-height: 1.6;
            padding: 0.5rem;
          }

          @keyframes dots {
            0% {
              background-color: var(--c);
            }
            50%,
            100% {
              background-color: var(--dots-bgc);
            }
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(1turn);
            }
          }


        `}</style>
      </div>
      <section className="property-list-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 floatright all-property">
              <div className="row property-list-all">
                {Response.properties.map((property, key) => (
                  <PropertyCard key={key} {...{ property }} />
                ))}

                <style>
                  {`
                    .property-hover:hover {
                      -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
                      -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
                      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
                      
                    }
                  `}
                </style>

                {Response && Response.pages > 1 && (
                  <div className="col-md-12 text-center">
                    {Loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        style={{
                          height: 60,
                          width: 300,
                        }}
                        className="btn btn-dark"
                        onClick={() => setLimit((prevVal) => prevVal + 20)}
                      >
                        VER MAS
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Properties;
