import React, { useEffect, useState } from "react";
import Slider from "./containers/Slider";

import httpClient, { strapi } from "../../utils/httpClient";
import numeral from "numeral";
import { Footer } from "../Footer";

const Home = () => {
  const [Properties, setProperties] = useState([]);
  const [Testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    httpClient
      .get("/properties?profileId=upfEjMZk&l=6&filters={}")
      .then(({ data }) => {
        console.log("wiglot data", data);
        setProperties(data.properties);
      });

    strapi.get("/testimonials").then(({ data }) => {
      console.log("testimonials =>", data);
      setTestimonials(data);
    });
  }, []);

  useEffect(() => {
    if (Testimonials.length) {
      //Testimonial slider
      window.$(".client-content").bxSlider({
        pagerCustom: ".client-img",
      });
    }
    //window.loadScript(["/js/main.js"]);
    return () => {};
  }, [Testimonials]);

  return (
    <div>
      <Slider />

      {/* <section className="filter-area filter-v1 section">
        <div className="container">
          <div className="row filter heading">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              <h4>
                Encuentra tu casa <i className="fa fa-search"></i>
              </h4>
            </div>
          </div>
          <div className="row filter box">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              <ul>
                <li>
                  <input className="input" placeholder="Escribe ubicacion" />
                </li>
                <li>
                  <select className="selectpicker">
                    <option>Tipo de propiedad</option>
                    <option>Casa</option>
                    <option>Casa duplex</option>
                    <option>Departamento</option>
                    <option>Oficina</option>
                  </select>
                </li>
                <li>
                  <select className="selectpicker">
                    <option>Tipo de operacion</option>
                    <option>Venta</option>
                    <option>Renta</option>
                    <option>Preventa</option>
                  </select>
                </li>
              </ul>
              <ul>
                <li>
                  <div className="search-btn">
                    <a className="btn btn-b">Search</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="filter-box-shadow"
            style={{
              backgroundImage: "url(images/Untitled_Artwork-1.png)",
            }}
          ></div>
        </div>
      </section> */}

      <section
        className="properties-area section"
        style={{
          marginTop: 50,
          paddingBottom: 50,
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              <div className="section-heading-v1 text-center">
                <h3>
                  <span>Tus sueños </span> nuestros sueños
                </h3>
                {/* <p>Tenemos la opcion perfecta para ti</p> */}
              </div>
            </div>
          </div>

          <iframe
            className="video-home"
            src="https://www.youtube.com/embed/NqJExNcZvLA"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <style>
          {`
          .video-home {
                width : 860px;
                height : 480px;
              }

              @media (max-width:770px){
              .video-home {
                width : 560px;
                height : 330px;
              }
            }

            @media (max-width:600px){
              .video-home {
                width : 360px;
                height : 230px;
              }
            }
          `}
        </style>
      </section>

      <section
        className="feature-area section"
        style={{
          backgroundImage:
            "url(images/florian-schmidinger-b_79nOqf95I-unsplash.jpg)",
          position: "relative",
          marginTop: 0,
        }}
      >
        <div className="container">
          <div
            className="row"
            style={{
              height: "100%",
            }}
          >
            <div className="col-xs-12">
              <div
                className="feature-content"
                style={{
                  marginTop: 0,
                  //paddingTop: 50,
                }}
              >
                <h3
                  style={{
                    marginBottom: 25,
                  }}
                >
                  <span>Servicios</span> Profesionales
                </h3>
                <ul>
                  <li>
                    <h4>
                      <i className="fa fa-image"></i> VENTA CASA HABITACION
                    </h4>
                    <p
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      Contamos con un amplio catálogo de propiedades, que te
                      permitirá comparar y tomar la mejor de las decisiones.
                      Tenemos los mejores inmuebles para ofrecer: cerca de áreas
                      verdes, zonas escolares, parques, jardines y servicios de
                      salud de primer nivel.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <i className="fa fa-image"></i> LOTES Y TERRENOS
                    </h4>
                    <p
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      Si lo que buscas es realizar un proyecto constructivo,
                      contamos con opciones de lotes y terrenos en donde puedes
                      construir el proyecto que desees; ya sea rural para tu
                      casa de descanso o en la zona urbana para tu casa
                      habitación o plan de inversión.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <i className="fa fa-image"></i> INMUEBLES COMERCIALES
                    </h4>
                    <p
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      Si tienes una empresa o negocio, contamos con propiedades
                      cerca de centros urbanos y a corta distancia de un mercado
                      de clientes potenciales, en espera de nuevos productos y
                      servicios.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <style>
            {`
          .feature-area {
                
                height : 650px;
              }

              @media (max-width:600px){
              .feature-area {
                
                height : auto;
              }
            }

            
          `}
          </style>
        </div>
      </section>

      <section className="about-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="about-content">
                <h2>
                  ¡<span>ENCUENTRA</span> lo que buscas!
                </h2>
                {/* <h4>
                  Trabajamos con tus necesidades, tanto en presupuesto mt2,
                  ambientes, ubicacion ¡Somos Expertos!
                </h4> */}

                <p>
                  En INMOBILIA ONLINE tenemos en mente al cliente, brindando
                  especial atención a trabajar por encontrar lo que este en sus
                  posibilidades y presupuesto, siempre cuidando la inversión y
                  recursos. ¡Te asesoramos gratuitamente!
                </p>

                <a href="/contact" className="btn-a">
                  CONTACTANOS
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="about-image">
                <img
                  src="images/photo-1580587771525-78b9dba3b914.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="started-area section"
        style={{
          backgroundImage: "url('images/clayton-1by_GbwEMwc-unsplash.jpg')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1 col-sm-12 col-xs-12 text-center">
              <div className="started">
                <h1>EMPIEZA TU BUSQUEDA HOY</h1>
                <p>
                  Comienza tu búsqueda hoy y selecciona las opciones que se
                  ajustan a tus necesidades y se encuentren en tu presupuesto.
                </p>
                <ul>
                  <li>
                    <a href="/search-sections" className="btn-a">
                      Ir al buscador
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-area testimonial-v1  section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              <div className="section-heading-v1 text-center">
                <h3>
                  <span>OPINION</span> DE NUESTROS CLIENTES
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="client-content">
                {Testimonials.map((testimony, key) => {
                  return (
                    <blockquote key={key}>
                      <p>{testimony.testimony} </p>
                    </blockquote>
                  );
                })}
              </div>
            </div>
            <div className="col-md-12">
              <div className="client-img text-center">
                {Testimonials.map((test, key) => {
                  return (
                    <a data-slide-index="0" href="" key={key}>
                      <div className="over-img">
                        <img src={test.avatar.url} alt="" />
                      </div>
                      <h4>{test.name}</h4>
                      <p>{test.role}</p>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
