import React from "react";

const SliderItem = (props) => {
  return (
    <div
      className="slider-item"
      style={{
        backgroundImage: "url('" + props.image + "')",
        height: 600,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div
        className="content-area"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 text-center">
              <div className="slider-content">
                <h1
                  style={{
                    fontSize: 50,
                    textShadow: "2px 2px #0000004F",
                  }}
                >
                  {props.title}
                </h1>
                <h1
                  className="larg"
                  style={{
                    textShadow: "2px 2px #0000004F",
                    fontSize: 35,
                  }}
                >
                  {props.subtitle}
                </h1>
                <p
                  style={{
                    textShadow: "2px 2px #0000004F",
                    textAlign: "justify",
                  }}
                >
                  {props.description}
                </p>
                <a href="/search-sections" className="btn btn-a" style={{}}>
                  Ver Propiedades
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Slider = (props) => {
  const slides = [
    {
      image:
        "images/slider/digital-marketing-agency-ntwrk-g39p1kDjvSY-unsplash.jpg",
      title: "Te ayudamos".toUpperCase(),
      subtitle: "a encontrar la mejor opción",
      description: `Somos una comunidad de agencias y agentes inmobiliarios que en conjunto conformamos un amplio y actualizado catálogo de propiedades con el propósito de brindarte una búsqueda ágil, fácil y amigable.`,
    },
    {
      image: "images/slider/florian-schmidinger-b_79nOqf95I-unsplash.jpg",
      title: "Todo en".toUpperCase(),
      subtitle: "un mismo sitio",
      description: `Ingresa a nuestro buscador y clasifica tu búsqueda por tipo de propiedad, rango de precio, ubicación, amenidades, metros cuadrados, entre otras características.
Aquí encontraras amplia variedad de bienes inmuebles, tanto en venta y renta: casas habitación, departamentos, terrenos habitacionales, locales comerciales, lotes rústicos, terrenos comerciales, etc.`,
    },
    {
      image: "images/slider/kara-eads-L7EwHkq1B2s-unsplash.jpg",
      title: "No corras riesgos".toUpperCase(),
      subtitle: "somos los indicados",
      description: `Todo nuestro catálogo de propiedades cuenta con la garantía de ser gestionada por un profesional inmobiliario, brindándote la seguridad que requieres al momento de adquirir un inmueble.`,
    },
  ];

  return (
    <section className="slider-area">
      <div className="slider-home1">
        {slides.map((slide, index) => (
          <SliderItem key={index} {...slide} />
        ))}
      </div>
      <style>
        {`
        .slider-item::after {
          background-color : #000000AF;
          color : #333;
          content : "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position:absolute;
        }
        `}
      </style>
    </section>
  );
};

export default Slider;
