import React, { useEffect } from "react";
import Header from "./containers/Header";
import { Switch, Route, Link } from "react-router-dom";

import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Afiliates from "./pages/Afiliates";
import Properties from "./pages/Properties";
import SearchSection from "./pages/SearchSection";
import { BlogDetails } from "./pages/Blog/BlogDetails";

const MainWrapper = () => {
  useEffect(() => {
    window.loadScript(["/js/main.js"]);
    return () => {};
  }, []);
  return (
    <div>
      <Header />
      <Switch>
        {/* <Route path="/about">
          <About />
        </Route>
        <Route path="/users">
          <Users />
        </Route> */}
        <Route path="/afiliates">
          <Afiliates />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/blog/:id">
          <BlogDetails />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>

        <Route path="/search-sections">
          <SearchSection />
        </Route>

        <Route path="/property/:id">
          <Properties />
        </Route>

        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
};

export default MainWrapper;
