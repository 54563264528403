import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="footer-last last-v1">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <p>©2020. Todos los derechos reservados. </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
