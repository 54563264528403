import axios from "axios";
const instance = axios.create({
  //baseURL: "https://wiggot.com/ssr/api/profile",
  baseURL:
    "https://proxy.inmobiliaonline.com.mx/wiggot.com:443/ssr/api/profile",
  headers: {},
});

const locationAPI = axios.create({
  //baseURL: "https://wiggot.com/ssr/api/profile",
  baseURL: "https://proxy.inmobiliaonline.com.mx/wiggot.com:443/api/v2.0.1",
  headers: {},
});

instance.getMedia = (mediaId) => {
  //return `http://0.0.0.0:8080/https://media.wiggot.pro/${mediaId}-m.jpg`;
  return `https://media.wiggot.pro/${mediaId}-m.jpg`;
};

const strapi = axios.create({
  baseURL: "https://api.inmobiliaonline.com.mx",
});

strapi.getMedia = (url) => {
  return strapi.defaults.baseURL + url;
};

export default instance;

export { locationAPI, strapi };
