import React, { useState, useEffect, useRef } from "react";
import { strapi } from "../utils/httpClient";
import { useHistory } from "react-router-dom";

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const Header = (props) => {
  const history = useHistory();
  console.log("props", history);
  const pathname = history.location.pathname;
  const [Sections, setSections] = useState([]);

  const prevCount = usePrevious(Sections);

  console.log("prevCount", prevCount);
  useEffect(() => {
    strapi.get("/search-sections").then(({ data }) => {
      setSections(data);
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (prevCount && prevCount.length == 0 && Sections.length > 0)
      window.$("nav#dropdown").meanmenu();
  }, [Sections]);

  return (
    <div>
      <header>
        <div className="header-v1">
          <div className="header-top">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-8 col-xs-12 text-left">
                  <ul className="top-contacts">
                    <li>
                      <i className="fa fa-envelope"></i>{" "}
                      inmobiliamarketing@gmail.com
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://wa.me/message/MPAK4EXPFMZAG1"
                      >
                        <i
                          className="fa fa-whatsapp"
                          style={{
                            color: "green",
                          }}
                        ></i>{" "}
                        (312) 312 7254
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4  col-sm-12 col-xs-12 social-list">
                  <ul className="top-social">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/INMOBILIAONLINE-102260084828400/"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://instagram.com/inmobiliaonline01?igshid=1ffe5xauyrjj4"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://m.youtube.com/channel/UCQpb5rjoQWkuStauO4PRwOA"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    {/*<li>
                      <a href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-skype"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="header-navbar">
            <div className="container">
              <div className="row">
                <div className="col-md-2 col-sm-12 col-xs-12">
                  <div className="header-logo">
                    <a href="index.html">
                      <img src="images/logo/inmobilia_logo NEGRO.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                  <div className="phone-menu">
                    <i className="fa fa-bars show-menu"></i>
                  </div>
                  <div className="main-menu text-center">
                    <nav>
                      <ul>
                        <li className={pathname == "/" ? "active" : ""}>
                          <a href="/">Inicio</a>
                        </li>

                        <li
                          className={
                            pathname == "/search-sections" ? "active" : ""
                          }
                        >
                          <a href="/search-sections">Regiones</a>
                        </li>

                        <li
                          className={
                            pathname.indexOf("/property") > -1 ? "active" : ""
                          }
                        >
                          <a>
                            Buscar Propiedades{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul>
                            {Sections.map((section, key) => (
                              <li key={key}>
                                <a href={"/property/" + section.id}>
                                  {section.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li
                          className={pathname == "/afiliates" ? "active" : ""}
                        >
                          <a href="/afiliates">Afiliados</a>
                        </li>

                        <li
                          className={
                            pathname.indexOf("/blog") > -1 ? "active" : ""
                          }
                        >
                          <a href="/blog">Blog</a>
                        </li>
                        <li className={pathname == "/contact" ? "active" : ""}>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="mobile-menu">
                  <nav id="dropdown">
                    <ul>
                      <li>
                        <a href="/" className={pathname == "/" ? "active" : ""}>
                          Inicio
                        </a>
                      </li>
                      <li
                        className={
                          pathname == "/search-sections" ? "active" : ""
                        }
                      >
                        <a href="/search-sections">Regiones</a>
                      </li>
                      <li>
                        <a>
                          Buscar Propiedades{" "}
                          <i className="fa fa-angle-down"></i>
                        </a>
                        <ul>
                          {Sections.map((section, key) => (
                            <li key={key}>
                              <a href={"/property/" + section.id}>
                                {section.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className={pathname == "/afiliates" ? "active" : ""}>
                        <a href="/afiliates">Afiliados</a>
                      </li>

                      <li
                        className={
                          pathname.indexOf("/blog") > -1 ? "active" : ""
                        }
                      >
                        <a href="/blog">Blog</a>
                      </li>
                      <li className={pathname == "/contact" ? "active" : ""}>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
