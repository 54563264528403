import React, { useEffect, useState } from "react";
import { strapi } from "../../utils/httpClient";
import { useParams } from "react-router-dom";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import seo from "../../utils/seo";

export const BlogDetails = () => {
  const [CurrentPost, setCurrentPost] = useState();
  let { id } = useParams();
  useEffect(() => {
    if (id)
      strapi.get("/posts/" + id).then(({ data }) => {
        console.log("/posts", data);
        setCurrentPost(data);
        seo({
          title: data.title,
          description: data.description,
        });
      });
    return () => {};
  }, [id]);

  if (!CurrentPost) {
    return null;
  }
  return (
    <div>
      <div
        className="shadow"
        style={{
          height: 350,
          backgroundColor: "#333",
          padding: 10,
          border: "0.5px solid #333",
          backgroundImage:
            "url(" + strapi.getMedia(CurrentPost.image.url) + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
        }}
      ></div>

      <div
        className="container"
        style={{
          marginTop: 50,
          textAlign: "left",
        }}
      >
        <h1>{CurrentPost.title}</h1>
        <ReactMarkdown plugins={[gfm]} children={CurrentPost.content} />
      </div>
    </div>
  );
};

export default BlogDetails;
