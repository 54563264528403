import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { strapi } from "../../utils/httpClient";
import { Footer } from "../Footer";

export const SearchSection = () => {
  const [Sections, setSections] = useState([]);
  const history = useHistory();
  useEffect(() => {
    strapi.get("/search-sections").then(({ data }) => {
      setSections(data);
    });
    return () => {};
  }, []);

  return (
    <div>
      <div
        className="container"
        style={{
          paddingTop: 50,
          paddingBottom: 100,
        }}
      >
        <h1>Regiones</h1>
        <div className="row">
          {Sections.map((section, key) => (
            <div
              key={key}
              onClick={() => {
                history.push(`/property/${section.id}`);
              }}
              className="col-md-6 col-xs-12 shadow overlay2"
              style={{
                height: 200,
                backgroundImage: `url(${section.image.url})`,
                display: "flex",

                position: "relative",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className=""
                style={{
                  fontSize: 60,
                  color: "#fff",
                  zIndex: 999,
                }}
              >
                {section.title}
              </span>
            </div>
          ))}
        </div>
      </div>
      <style>{`
            .overlay2 {
                cursor:pointer;
                background-size: 800px auto;
            }
            .overlay2:before {
                top : 0;
                left : 0;
                right : 0;
                content : "";
                height : 100%;
                background: rgba(0, 0, 0, 0.45);
                position : absolute;
            }

            .shadow:hover {
                -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
                -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
                box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
                

            }

             .overlay2:hover {
                background-size:cover;
                border : 5px solid #fff;

            }
          `}</style>
      <Footer />
    </div>
  );
};

export default SearchSection;
