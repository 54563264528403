import React, { useEffect, useState } from "react";
import { strapi } from "../../utils/httpClient";

import { Switch, Route, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import moment from "moment";

export const Blog = () => {
  const [Page, setPage] = useState();
  const [Posts, setPosts] = useState([]);

  useEffect(() => {
    strapi.get("/blog").then(({ data }) => {
      console.log("/blog", data);
      setPage(data);
    });
    return () => {};
  }, []);

  useEffect(() => {
    strapi.get("/posts?_sort=published_at:DESC&_limit=10").then(({ data }) => {
      console.log("/posts", data);
      setPosts(data);
    });
    return () => {};
  }, []);

  if (!Page) {
    return (
      <div
        className="spinner-grow"
        style={{ width: 3, height: 3 }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <div>
      <section
        className="banner-area section"
        style={{
          backgroundImage:
            "url(" + strapi.defaults.baseURL + Page.header.url + ")",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="banner-content">
                <h3>NUESTROS ULTIMOS BLOGS</h3>
                <ul>
                  <li>
                    <a href="index.html">Inicio</a>
                  </li>
                  <li>></li>
                  <li>
                    <a href="#">
                      <span>Blog</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area section">
        <div className="container">
          <div className="row">
            {Posts.map((post, key) => (
              <div key={key} className="col-md-4 col-sm-6 col-xs-12 blog-grid">
                <div
                  className="single-blog"
                  style={{
                    padding: 10,
                  }}
                >
                  <figure
                    className={"shadow"}
                    style={{
                      height: 150,
                      width: "100%",
                      overflow: "hidden",
                      justifyContent: "center",

                      backgroundImage: `url(${strapi.getMedia(
                        post.image.url
                      )})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></figure>
                  <div className="blog-content">
                    <h4
                      style={{
                        fontWeight: "900",
                      }}
                    >
                      {post.title}
                    </h4>

                    <ReactMarkdown
                      plugins={[gfm]}
                      children={post.content.substring(0, 199) + "..."}
                    />
                    <a
                      href={"/blog/" + post.id}
                      className=""
                      style={{ color: "#0573ff", textDecoration: "underline" }}
                    >
                      SEGUIR LEYENDO...
                    </a>
                  </div>
                  <span
                    style={{
                      backgroundColor: "#dadada",
                      padding: 5,
                      borderRadius: 5,
                      fontWeight: "300",
                    }}
                  >
                    {moment(post.published_at).format("MMM Do YYYY, h:mm A")}
                  </span>
                </div>
              </div>
            ))}

            {/* <div className="col-md-12 text-center">
              <div className="pagin">
                <ul>
                  <li>
                    <span>
                      <i className="fa fa-angle-double-left"></i>
                    </span>
                  </li>
                  <li>
                    <span className="active">1</span>
                  </li>
                  <li>
                    <span>2</span>
                  </li>
                  <li>
                    <span>3</span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-angle-double-right"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
