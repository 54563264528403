import React from "react";
import numeral from "numeral";
import httpClient, { strapi } from "../../utils/httpClient";

export const PropertyCard = ({ property }) => {
  return (
    <div
      className="col-lg-4 col-md-6 col-sm-6 list-property"
      style={
        {
          //padding: 10,
        }
      }
    >
      <div
        className=" shadow property-hover"
        style={
          {
            //padding: 10,
          }
        }
      >
        <div
          style={{
            height: 300,
            backgroundSize: "cover",
            backgroundImage: `url(${httpClient.getMedia(property.images[0])})`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* http://www.inmobiliaonline.wiggot.com/properties?category_id=39&category=association&type=&operation= */}
          <a
            target="_blank"
            //hcRef={`https://wiggot.com/datasheet/${property.property_id}?profile_id=upfEjMZk`}
            href={`http://www.inmobiliaonline.wiggot.com/property/${property.property_id}`}
            className="btn btn-primary btn-l"
            style={{
              color: "#fff",
            }}
          >
            MAS INFO
          </a>
        </div>
        <div
          className="content-list"
          style={{
            position: "relative",
            paddingLeft: 10,
            paddingRigth: 10,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <span
            style={{
              marginTop: 5,
            }}
          >
            <h2
              style={{
                fontSize: 16,
                fontWeight: "700",
                marginBottom: 0,
                marginTop: 10,
                color: "#0757bd",
              }}
            >
              {property.neighborhood}
            </h2>
            <a
              href="#"
              style={{
                marginTop: 0,
                fontSize: 14,
              }}
            >
              {" "}
              {`${property.street} #${
                property.external_number ? property.external_number : ""
              }`}
            </a>
          </span>

          <span
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              textAlign: "right",
            }}
          >
            <p
              style={{
                marginBottom: 0,
                fontWeight: "800",
              }}
            >
              {numeral(property.sale_price).format("$0,0")}
              {property.sale_currency}
            </p>
            <p
              style={{
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              {numeral(property.lease_price).format("$0,0")}
              {property.lease_currency}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
