import React, { useState, useEffect } from "react";
import { strapi } from "../../utils/httpClient";
import { Footer } from "../Footer";

import Affiliation from "./Affiliation";

const Afiliates = () => {
  const [Companies, setCompanies] = useState([]);
  const [Agents, setAgents] = useState([]);
  useEffect(() => {
    strapi.get("/partners").then(({ data }) => {
      setCompanies(data);
    });

    strapi.get("/agents").then(({ data }) => {
      setAgents(data);
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (Companies.length > 0) {
      window.$(document).ready(function () {
        window
          .$(".customer-logos")
          .not(".slick-initialized")
          .slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            arrows: false,
            dots: false,
            pauseOnHover: false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 520,
                settings: {
                  slidesToShow: 3,
                },
              },
            ],
          });
      });
    }
    return () => {};
  }, [Companies]);

  // useEffect(() => {
  //   if (Testimonials.length) {
  //     //Testimonial slider
  //     window.$(".client-content").bxSlider({
  //       pagerCustom: ".client-img",
  //     });
  //   }
  //   //window.loadScript(["/js/main.js"]);
  //   return () => {};
  // }, [Testimonials]);

  useEffect(() => {
    if (Agents.length > 0) {
      window.$(document).ready(function () {
        //Home v1 - Agent slider
        // window.$(".agent-slider-v1").owlCarousel({
        //   items: 4,
        //   itemsDesktop: [1199, 3],
        //   itemsDesktopSmall: [980, 3],
        //   itemsTablet: [768, 2],
        //   itemsMobile: [479, 1],
        //   pagination: true,
        //   navigation: false,
        //   autoPlay: false,
        // });
        window.$(".client-content").bxSlider({
          pagerCustom: ".client-img",
        });
      });
    }
    return () => {};
  }, [Agents]);

  return (
    <div>
      <section
        className="banner-area section"
        style={{
          backgroundImage: "url(images/benno-klandt-Qr5pi1_GlvY-unsplash.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="banner-content">
                <h3>Afiliados</h3>
                <ul>
                  <li>
                    <a href="/">Inicio</a>
                  </li>
                  <li>></li>
                  <li>
                    <a href="#">
                      <span>Afiliados</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div
                className="about-content"
                style={{
                  textAlign: "justify",
                }}
              >
                <h2>
                  AFILIATE <span>A NUESTRO EQUIPO</span>
                </h2>
                <h4>
                  ¿Estas interesado en crecer tu equipo de ventas de tu catálogo
                  de inmuebles?
                </h4>

                <p>
                  ¿Deseas incrementar el número de inmuebles para promocionar?
                  <br />
                  <br />
                  ¿Buscas tener un mayor impacto en las redes sociales y en los
                  buscadores de internet? <br /> <br /> Forma parte de campañas
                  estratégicas que redituara en la obtención de mayor número de
                  prospectos para poder comercializar tus propiedades
                  inmobiliarias.
                </p>

                <a
                  target="_blank"
                  href="https://checkout.inmobiliaonline.com.mx"
                  className="btn-a"
                >
                  AFILIATE
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="about-image">
                <img
                  src="images/dylan-gillis-KdeqA3aTnBY-unsplash.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-area">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1  col-sm-12">
              <div className="section-heading-v1 text-center">
                <h2
                  style={{
                    fontSize: 40,
                  }}
                >
                  ¡UNETE <span>YA!</span>{" "}
                </h2>
                <p
                  style={{
                    fontSize: 16,
                  }}
                >
                  ¿Te gustaría formar parte de la comunidad y apalancarte de las
                  herramientas para aumentar tu número de prospectos y vender
                  más rápido tus propiedades?
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <iframe
              className="video-home"
              style={{
                marginTop: 50,
                marginBottom: 50,
              }}
              src="https://www.youtube.com/embed/_z_RoABsNro"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <style>
            {`
          .video-home {
                width : 860px;
                height : 480px;
              }

              @media (max-width:770px){
              .video-home {
                width : 560px;
                height : 330px;
              }
            }

            @media (max-width:600px){
              .video-home {
                width : 360px;
                height : 230px;
              }
            }
          `}
          </style>
          <p
            style={{
              fontSize: 16,
              textAlign: "justify",
            }}
          >
            {" "}
            En la actualidad los medios digitales nos brindan la oportunidad de
            difundir información de valor para atraer a nuestro público objetivo
            y lograr retener su atención, para convertirlo en un prospecto
            (leed) que si lo llevamos por un buen proceso de comercialización
            lograremos cerrar negocios exitosos. <br /> <br />{" "}
            <strong>INMOBILIA ONLINE</strong> brinda a sus afiliados, una
            estrategia comercial sustentada en la colaboración, la
            implementación de campañas de marketing focalizadas y el
            apalancamiento de herramientas digitales especializadas.
            <br />
            <br /> Al ser parte de nuestro equipo de afiliados obtendrás el
            beneficio de pertenecer a una red de colaboración integrada por
            agencias y agentes profesionales inmobiliarios, que juntos
            conformaran una bolsa inmobiliaria única, la cual se promocionara
            con estrategias de marketing digital.
            <br />
            <br /> Cualquier inmueble incluido en la bolsa inmobiliaria podrá
            ser promovido por todos los integrantes, generando automáticamente
            un incremento de inmuebles en tu catálogo de propiedades y
            adicionalmente una mayor probabilidad de éxito en la colocación de
            tus inmuebles promovidos de manera directa.
          </p>
        </div>
      </section>

      <section className="skill-area">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <img src="" />
            </div>
            <div className="col-9"></div>
          </div>
        </div>
      </section>

      <section
        className="agent-area testimonial-area  testimonial-v1  section"
        style={{
          height: 530,
          color: "#fff",
          backgroundImage:
            "url(images/francesca-tosolini-tHkJAMcO3QE-unsplash.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              <div
                className="section-heading-v1 agent-header text-center"
                style={{
                  paddingTop: 40,
                }}
              >
                <h2>
                  AGENTES <span>INMOBILIARIO</span>
                </h2>
                <p>Forma parte de nuestro equipo de socios expertos</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="client-content">
                {Agents.map((testimony, key) => {
                  return (
                    <blockquote key={key}>
                      <p>{testimony.testimony} </p>
                    </blockquote>
                  );
                })}
              </div>
            </div>
            <div className="col-md-12">
              <div className="client-img text-center">
                {Agents.map((test, key) => {
                  return (
                    <a data-slide-index="0" href="" key={key}>
                      <div className="over-img">
                        <img src={test.avatar.url} alt="" />
                      </div>
                      <h4
                        style={{
                          color: "#fff",
                        }}
                      >
                        {test.name}
                      </h4>
                      <p
                        style={{
                          color: "#fff",
                        }}
                      >
                        {test.role}
                      </p>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <style>{`
        .agent-area:before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              height: 530px;
              background: rgba(0, 0, 0, 0.75);
        }

        .testimonial-area .client-content blockquote:before {
            content: "\f10d";
            position: absolute;
            color : #ffffff !important;
            font-family: "fontawesome";
            font-size: 30px;
        }

        .testimonial-area .bx-wrapper .bx-prev:before {
            position: absolute;
            content: "\f177";
            font-family: "fontawesome";
            font-size: 18px;
            left: 0;
            top: 135px;
            width: 36px;
            height: 34px;
            padding-top: 5px;
            background: #fff;
            border: 1px solid #dddddd;
            transition: all 0.5s ease 0s;
        }

        .testimonial-area .bx-wrapper .bx-next:before {
            position: absolute;
            content: "\f178";
            font-family: "fontawesome";
            font-size: 18px;
            right: 0;
            top: 135px;
            width: 36px;
            height: 34px;
            padding-top: 5px;
            background: #fff;
            border: 1px solid #dddddd;
            transition: all 0.5s ease 0s;
        }
      `}</style>
      <section className="about-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="about-image">
                <img
                  src="images/you-x-ventures-Oalh2MojUuk-unsplash.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div
                className="about-content"
                style={{
                  textAlign: "justify",
                }}
              >
                <h2>
                  BENEFICIOS<span> AFILIADOS</span>
                </h2>
                <h4>¿Qué obtienes al ser parte de nuestro equipo?</h4>

                <ul
                  style={{
                    listStyle: "initial",
                    backgroundColor: "transparent",
                    paddingLeft: 20,
                    marginBottom: 40,
                  }}
                >
                  <li>
                    Ser parte del catálogo inmobiliario más grande de la región.
                  </li>
                  <li>
                    Administración de cuenta de la plataforma digital wiggot.
                  </li>
                  <li>
                    Página web gratuita proporcionada por la plataforma digital
                    wiggot.
                  </li>
                  <li>
                    Asesoría y atención personalizada por parte de un asesor
                    calificado.
                  </li>
                  <li>
                    Exposición en redes sociales y buscadores en internet.
                  </li>
                  <li>
                    Campañas de marketing estratégicamente planeadas por nuestro
                    equipo de profesionales, enfocada a promocionar nuestra
                    bolsa de colaboración inmobiliaria.
                  </li>
                  <li>
                    Formar parte de INMOBILIA UNIVERSITY donde se imparten
                    cursos de capacitación para ti y tu equipo.
                  </li>
                  <li>
                    Precios preferenciales en los servicios de nuestros aliados
                    comerciales; dentro de estos se encuentran la elaboración de
                    recorridos virtuales 360°, edición de fotografía y video
                    digital, asesoría jurídica especializada en arrendamiento,
                    análisis financieros de proyectos de inversión.
                  </li>
                </ul>

                <a
                  target="_blank"
                  href="https://checkout.inmobiliaonline.com.mx"
                  className="btn-a"
                >
                  AFILIATE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=""
        style={{
          backgroundColor: "#111111",
        }}
      >
        <div
          className="container"
          style={{
            paddingBottom: 20,
          }}
        >
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              <div className="section-heading-v1 agent-header text-center">
                <h2
                  style={{
                    color: "#fff",
                    marginTop: 50,
                    marginBottom: 10,
                  }}
                >
                  ALIADOS <span>COMERCIALES</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="customer-logos">
            {Companies.map((company, key) => (
              <div key={key} className="slide">
                <img src={company.logo.url} height="50" />
              </div>
            ))}
          </div>
        </div>
      </section>

      <style>
        {`
          .customer-logos {
            background-color: #111;
            padding-top : 20px;
            padding-bottom : 20px;
          }

          /* Slider */
          .slick-slide {
              margin: 0px 20px;
          }

          .slick-slide img {
              width: 100%;
          }

          .slick-slider
          {
              position: relative;
              display: block;
              box-sizing: border-box;

              -webkit-user-select: none;
                -moz-user-select: none;
                  -ms-user-select: none;
                      user-select: none;

              -webkit-touch-callout: none;
              -khtml-user-select: none;
              -ms-touch-action: pan-y;
                  touch-action: pan-y;
              -webkit-tap-highlight-color: transparent;
          }

          .slick-list
          {
              position: relative;
              display: block;
              overflow: hidden;

              margin: 0;
              padding: 0;
          }
          .slick-list:focus
          {
              outline: none;
          }
          .slick-list.dragging
          {
              cursor: pointer;
              cursor: hand;
          }

          .slick-slider .slick-track,
          .slick-slider .slick-list
          {
              -webkit-transform: translate3d(0, 0, 0);
                -moz-transform: translate3d(0, 0, 0);
                  -ms-transform: translate3d(0, 0, 0);
                  -o-transform: translate3d(0, 0, 0);
                      transform: translate3d(0, 0, 0);
          }

          .slick-track
          {
              position: relative;
              top: 0;
              left: 0;
               
              display: flex;
              align-items: center;
            

              
          }
          .slick-track:before,
          .slick-track:after
          {
              display: table;

              content: '';
          }
          .slick-track:after
          {
              clear: both;
          }
          .slick-loading .slick-track
          {
              visibility: hidden;
          }

          .slick-slide
          {
              display: none;
              float: left;

              height: 100%;
              min-height: 1px;
          }
          [dir='rtl'] .slick-slide
          {
              float: right;
          }
          .slick-slide img
          {
              display: block;
          }
          .slick-slide.slick-loading img
          {
              display: none;
          }
          .slick-slide.dragging img
          {
              pointer-events: none;
          }
          .slick-initialized .slick-slide
          {
              display: block;
          }
          .slick-loading .slick-slide
          {
              visibility: hidden;
          }
          .slick-vertical .slick-slide
          {
              display: block;

              height: auto;

              border: 1px solid transparent;
          }
          .slick-arrow.slick-hidden {
              display: none;
          }
        `}
      </style>

      <Footer />
    </div>
  );
};

export default Afiliates;
