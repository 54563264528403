import React, { useState } from "react";
import emailjs, { init } from "emailjs-com";
init("user_SUN07h7mz4Ram9QI5T6to");

const Contact = () => {
  const [Form, setForm] = useState({
    name: "",
    message: "",
    number: "",
    email: "",
  });
  const [Sending, setSending] = useState(false);
  const sendData = () => {
    const templateParams = {
      from_name: Form.name,
      message: Form.message,
      number: Form.number,
      email: Form.email,
    };
    setSending(true);
    emailjs.send("service_u4cjeuq", "template_7uddtqj", templateParams).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        setForm({
          name: "",
          message: "",
          number: "",
          email: "",
        });
        setSending(false);
        alert("Gracias por contactarnos!");
      },
      function (error) {
        console.log("FAILED...", error);
        setSending(false);
        alert("Error al enviar formulario");
      }
    );
  };

  //console.log("Form", Form);
  return (
    <div>
      <section
        className="banner-area section"
        style={{
          backgroundImage:
            "url(images/volodymyr-hryshchenko-V5vqWC9gyEU-unsplash.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="banner-content contact-page">
                <h3>Contactanos</h3>
                <ul>
                  <li>
                    <a href="/">Inicio </a>
                  </li>
                  <li> > </li>
                  <li>
                    <a href="#">
                      <span> Contactanos</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="mail-to">
                <h4>Envianos un mensaje</h4>

                <ul>
                  <li>
                    {" "}
                    <input
                      type="text"
                      name="name"
                      value={Form.name}
                      onChange={(e) =>
                        setForm((prevVal) => {
                          let tempVal = Object.assign({}, prevVal);
                          tempVal[e.target.name] = e.target.value;
                          return tempVal;
                        })
                      }
                      placeholder="Tu Nombre"
                    />
                  </li>
                  <li>
                    <input
                      type="email"
                      name="email"
                      value={Form.email}
                      onChange={(e) =>
                        setForm((prevVal) => {
                          let tempVal = Object.assign({}, prevVal);
                          tempVal[e.target.name] = e.target.value;
                          return tempVal;
                        })
                      }
                      placeholder="Tu Correo"
                    />
                  </li>
                  <li>
                    <input
                      type="text"
                      name="number"
                      value={Form.number}
                      onChange={(e) =>
                        setForm((prevVal) => {
                          let tempVal = Object.assign({}, prevVal);
                          tempVal[e.target.name] = e.target.value;
                          return tempVal;
                        })
                      }
                      placeholder="Numbero de telefono"
                    />
                  </li>
                </ul>
                <textarea
                  rows="8"
                  name="message"
                  value={Form.message}
                  onChange={(e) =>
                    setForm((prevVal) => {
                      let tempVal = Object.assign({}, prevVal);
                      tempVal[e.target.name] = e.target.value;
                      return tempVal;
                    })
                  }
                  placeholder="Tu Mensaje"
                ></textarea>
                <button
                  onClick={sendData}
                  disabled={Sending}
                  style={{
                    backgroundColor: "#2090b2",
                  }}
                  className="btn-a"
                >
                  Enviar correo
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="list-sidber">
                <h2>Contactanos</h2>
                <p>¿Necesitas más información? Contáctanos</p>
                <ul>
                  <li>
                    <span className="ico-sp">
                      <i className="fa fa-phone"></i>
                    </span>
                    <span className="txt-sp"> (312) 312 7254</span>
                  </li>
                  <li>
                    <span className="ico-sp">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <span className="txt-sp">inmobiliamarketing@gmail.com</span>
                  </li>
                  <li>
                    <span className="ico-sp">
                      <i className="fa fa-clock-o"></i>
                    </span>
                    <span className="txt-sp">
                      Horarios; lunes a viernes 9-2 y 4-7 sábados 9-2
                    </span>
                  </li>
                  <li>
                    <span className="ico-sp">
                      <i className="fa fa-map-marker"></i>
                    </span>
                    <span className="txt-sp">
                      Calle Camino Real 300, Colima, Colima, 28017
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="footer-last last-v1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>©2020. Todos los Derechos Reservados. </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
